.informationContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.informationContainer-Section{
    padding: 50px;
}


.paymentIcons{
    font-size: 40px;
}
.expirationGroup{
    display: flex;
}

.expirationGroup select {
    margin-right: 10px;
}

.ordersTitle{
    padding: 25px;
}

.orderOptions{
    display: flex;
    display: flex;
    justify-content: space-around;

}
.ordersRecord{
    display: flex;
    justify-content: space-between;
}
.modal-backdrop {
    z-index: -1;
}

.orderOptions button {
    
    margin-right: 5px;
}

.cartButton{
    display: flex;
    font-size: 15px;
    width: 10px;
    height: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
