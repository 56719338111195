.headerInformation{
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}
.lefManContainer{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.leftManContainer-footer{
    display: flex;
    flex-direction: column;
}
.leftManContainer-footer--buttonBox{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.ManRightSide{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ManLeftSide{
    padding: 10px;
}