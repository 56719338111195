.carouselSection img{
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 25px;
    width: 35%;

}

.carousel-item p, h5 {
    color: black;
}

.carousel-item span{
    background-color: black;
}
.carouselSection{
    text-align: center;
    padding: 25px;
}
.carouselSection h2{
    margin-bottom: 35px;
}

.carousel-caption{
    margin-top: 100px;
}