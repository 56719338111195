.paymentCard{
    margin-top: 45px;
    height: auto;
}

.adressButton{
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.billingAdressContainer{
    height: 160px;
}
.deliveryAdressContainer{
    height: 255px;
}