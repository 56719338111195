.mainBakcground{
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
}
.loginFormCard{
    display: flex;
    padding: 30px;
}
.AdmLoginLogo{
    width: 80px;
    height: 80px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
}