.sidebar-container{
    min-height: 100vh;
    width: auto;
    padding: 20px;
    position: relative;
    z-index: 100;
    left: 0;
    top: 0;
}


#sidebar-toggle{
    display: none;
}

.sidebar-container .dashboardLogo{
    padding: .875rem 1.25rem;
    display: flex;
    margin-bottom: 50px;
    justify-content: space-around;
    vertical-align: middle;
    align-items: center;
}

#sidebar-toggle:checked ~ .sidebar-container {
    width: auto;
    padding: 5px;
}

#sidebar-toggle:checked ~ .sidebar-container .dashboardLogo h4 span:last-child,
#sidebar-toggle:checked ~ .sidebar-container .dashboardMenu li a span:last-child {
    display: none;

}

.dashboardMenu{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80vh;
}

.bg-sidebar{
    background-color: #4954A2 !important;
}

.dashboardMenu a:hover {
    text-decoration: none;
}

.dashboardMenu a {
    width: 100%;
    margin-bottom: 50px;
    text-decoration: none;
}

.dashboardImg{
    max-width: 35px;
}

.dashboardContent{
    overflow-y: auto;
    height: auto;
    padding-bottom: 5rem;
    padding: 25px;

}

.stat{
    border-right: 1px solid lightgray;
}

.bg-mix{
    background: linear-gradient(180deg, #ffffff 50%, #efefef 0);
}
.firstContainer{
    margin: 25px;
    border: 1px solid lightgray;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3);
    
}

.secondContainer{
    padding: 25px;
}
.thirdContainer{
    background: #ffffff;
}

.dashboardCard{
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: start;
    padding: 25px;
    align-items: flex-start;
    margin: 25px;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3);
}

.fifthContainer{
    background: linear-gradient(180deg, #efefef 50%, #ffffff 0);
}

.styledCard  {
    padding: 40px !important;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3);
}

.statusBox {
    display: flex;
    border: 0.5px solid rgb(187, 187, 187);
    width: 96%;
    height: 250px;
    margin: 15px;
    padding: 25px;
}
.styledTopCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3);
}

.cardDetailsBox{
    border-right: 0.5px solid lightgray;
}

.dashboardCardOrder{
    flex-direction: column;
    justify-content: start;
    text-align: start;
    padding: 25px;
    align-items: flex-start;
    margin: 25px;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3);
}