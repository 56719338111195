.principalContainer {
    padding: 100px 0 160px;
    display: flex;
    top: 50px;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
    margin-bottom: 25px;
}

.mainWrapper {
    z-index: 1; 
    width: 100%;
    top: 50px;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
}


