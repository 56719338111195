.mainContainer{
    display: flex;
    justify-content: flex-start;
    align-items: row;
    width: 100%;
    height: 100%;
}
.leftContainer{
    display: flex;
    flex-direction: column;
    height: auto;
    border-right: 1px solid black;
    padding: 25px;
}

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.header-title{
    margin-bottom: 15px;
    
}

.header-logo{
    width: 80px;
    height: 80px;
    margin-bottom: 40px;
}

.leftContainer-footer{
    display: flex;
    flex-direction: column;
    
}

.leftContainer-footer--options{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.leftContainer-footer--buttonBox{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.rightRegisterContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

}

.rightContainer-register{
    padding: 30px;
}

