
.leftContainer{
    display: flex;
    flex-direction: column;
    height: auto;
    border-right: 1px solid black;
    padding: 25px;
}

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.header-title{
    margin-bottom: 15px;
    
}

.header-logo{
    width: 80px;
    height: 80px;
    margin-bottom: 40px;
}

.leftContainer-footer{
    display: flex;
    flex-direction: column;
}

.leftContainer-footer--options{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.leftContainer-footer--remember{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.rightContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

}

.rightContainer-register{
    padding: 30px;
}

.switch {
    position: relative;
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 34px;
    margin-bottom: 20px;
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #4954A2;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #3E4054;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(115px);
    -ms-transform: translateX(115px);
    transform: translateX(115px);
  }
  
  /*------ ADDED CSS ---------*/
  .on
  {
    display: none;
  }
  
  .on, .off
  {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    font-size: 15px;
    font-family: 'PT Sans', sans-serif;
  }
  
  input:checked+ .slider .on
  {display: block;}
  
  input:checked + .slider .off
  {display: none;}
  
  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider.round {
      width: 150px;
    border-radius: 10px;
  }
  
  .slider.round:before {
    border-radius: 5px;}