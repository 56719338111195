.sidebar-container{
    min-height: 100vh;
    width: auto;
    padding: 20px;
    position: relative;
    z-index: 100;
    left: 0;
    top: 0;
}


#sidebar-toggle{
    display: none;
}

.sidebar-container .dashboardLogo{
    padding: .875rem 1.25rem;
    display: flex;
    margin-bottom: 50px;
    justify-content: space-around;
    vertical-align: middle;
    align-items: center;
}

#sidebar-toggle:checked ~ .sidebar-container {
    width: auto;
    padding: 5px;
}

#sidebar-toggle:checked ~ .sidebar-container .dashboardLogo h4 span:last-child,
#sidebar-toggle:checked ~ .sidebar-container .dashboardMenu li a span:last-child {
    display: none;

}

.dashboardMenu{
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80vh;
}

.bg-primary{
    background-color: #111854 !important;
}

.dashboardMenu a:hover {
    text-decoration: none;
}

.dashboardMenu a {
    width: 100%;
    margin-bottom: 50px;
    text-decoration: none;
}

.dashboardImg{
    max-width: 35px;
}

.dashboardContent{
    overflow-y: auto;
    height: 100vh;
    background-color: #fafafa;
    padding-bottom: 5rem;
    padding: 25px;

}
.menu-item:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
  }

.adminInformationContainer{
    padding: 10px;
}
.informationCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3);
    margin-top: 15px;
    margin-bottom: 15px;
}

.site-logo{ 
    width: 150px;
    height: 150px;
    margin:  0 auto;
    padding: 15px;
}
.site-banner{ 
    width: 750px;
    height: 300px;
    margin:  0 auto;
    padding: 15px;
}
.site-brands{
    width: 75px;
    height: 75px;
    margin: 10px;
}
.imgGallery{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}