.qtyColumn{
    width: 250px;
    justify-content: center;
    align-items: center;
}
.productContainer-title{
    padding: 25px;
}
tbody{
    vertical-align: middle;
}
table{
    vertical-align: middle;
}
.qtyColumn{
    text-align: center;
}
.mytable>tbody>tr>td, .mytable>tbody>tr>th, .mytable>tfoot>tr>td, .mytable>tfoot>tr>th, .mytable>thead>tr>td, .mytable>thead>tr>th {
    padding: 20px;
    border-style: none;
}
.paymentCardTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5px;
}

.cardBody{
    display: flex;
    flex-direction: column;
    padding: 15px;
}
.cardBodysec{
    display: flex;
    justify-content: space-between;
}
.cardFooter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5px;
}
.cardButtonSec{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: flex-end;
}
.cardPaymentButton{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

}

.cartButton{
    display: flex;
    font-size: 15px;
    width: 10px;
    height: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
}