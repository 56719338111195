.formInputStyled {
    margin-top: 20px;
}

.gerberMainInformation {
    display: flex;
    flex-direction: column;
}

.gerberMainFile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gerberImg {
    width: 300px;
    height: 150px;
    margin: 15px;
}

.cardForm {
    padding: 30px;
    /*box-shadow: 3px 3px 2px 3px #888888;*/
}

.cardFormHeader {
    margin-bottom: 20px;
}

.dimensionBox {
    display: flex;
    align-items: inherit;
    justify-content: space-evenly;
}
.manufacturerSection {
    margin-top: 25px;
    height: 100%;
}
.manImg {
    width: 75px;
    height: 75px;
}
.manImgCont {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.gerberSectionContainer {
    margin-bottom: 50px;
}

.optionContainer {
    display: flex;
}

.dimensionBox {
    justify-content: space-between;
    align-items: center;
}

.progessBar {
    height: 1rem;
    width: 0%;
    background-color: rgb(68, 212, 231);
    color: white;
    padding: 2px;
}

.upbutton {
    width: 5rem;
    padding: 0.5rem;
    background-color: #2767e9;
    color: aliceblue;
    font-size: 1rem;
    cursor: pointer;
}

div.manufacturercontainer {
    position: -webkit-sticky;
    align-self: flex-start;
    position: sticky;
    top: 80px;
    margin: auto;
}

.Structure {
    display: flex;
    flex-direction: row;
}

@media (max-width: 765px) {
    .cardSection {
        margin-bottom: 30px;
    }
}
